import validator from 'validator';

// For exp date field
export function limit(val, max) {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

// For exp date field
export function cardExpiry(val) {
  let month = limit(val.substring(0, 2), '12');
  let year = val.substring(2, 4);

  return month + (year.length ? '/' + year : '');
}

// Validates step data
export function validateStep(data, messages) {
  const errors = {};

  Object.entries(messages).forEach(([key, value]) => {
    const fieldValue = String(data[key] || ''); // Convert value to string

    if (
      (key === 'email' && !validator.isEmail(fieldValue)) ||
      (key === 'CVV' && !fieldValue.match(/\d{3,4}/)) ||
      (key === 'creditCardNumber' && !validator.isCreditCard(fieldValue)) ||
      (key === 'phone' &&
        (fieldValue.replace(/[^+\d]+/gim, '').length !== 10 ||
          fieldValue.replace(/[^+\d]+/gim, '').startsWith('1'))) ||
      validator.isEmpty(fieldValue)
    ) {
      errors[key] = value;
    }
  });

  return errors;
}

export function isExpDateValid(month, year) {
  const monthToday = new Date().getMonth();
  const yearToday = new Date().getFullYear() - 2000;

  if (year * 1 === yearToday && monthToday >= month * 1) {
    return false;
  }

  return true;
}
