/* eslint-disable no-restricted-globals */

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import FreeProductList from '../../FreeProductList/FreeProductList';
import useInterval from '../../../hooks/useInterval';
import './UpsellModal.sass';

export default function UpsellModal({ modalIsOpen, closeModal, freeProducts, onSubmit, settings }) {
  const [seconds, setSeconds] = useState(60);
  const [isRunning, setIsRunning] = useState(false);

  Modal.setAppElement('#root');

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      overflowY: 'scroll',
      zIndex: 1000,
    },
    content: {
      padding: 0,
      inset: '20px 50% auto',
      width: '650px',
      left: '50%',
      top: '30px',
      border: 'none',
      maxWidth: '95%',
      transform: 'translateX(-50%)',
    },
  };

  function countdown() {
    if (seconds <= 0) {
      document.getElementById('countdown').innerHTML = '<span>0</span><span>0</span>';
    } else {
      const first = seconds >= 10 ? seconds.toString()[0] : 0;
      const second = seconds >= 10 ? seconds.toString()[1] : seconds;
      document.getElementById('countdown').innerHTML = `<span>${first}</span><span>${second}</span>`;
    }
    setSeconds(seconds - 1);
  }

  useInterval(
    () => {
      settings.countdown && countdown();
    },
    isRunning ? 1000 : null
  );

  useEffect(() => {
    if (modalIsOpen) {
      setIsRunning(true);
    } else {
      setIsRunning(false);
    }
  }, [modalIsOpen]);

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
      <div className='free-product-modal'>
        <div className='free-product-modal-warning'>HURRY UP! LIMITED QUANTITY AVAILABLE</div>
        <div className='free-product-modal-header'>
          <h4>
            {settings.title}

            {settings.countdown && (
              <div id='countdown' style={{ display: 'inline', textAlign: 'center' }}>
                <span>6</span>
                <span>0</span>
              </div>
            )}
          </h4>
          <p>{settings.subtitle}</p>
        </div>

        {settings.template === 'oneProduct' && (
          <div className='free-product-element'>
            <img src={settings.oneProductSettings?.imgUrl} alt='free product' />
            <button
              className='free-product-list-product-btn'
              onClick={() => onSubmit(settings.oneProductSettings)}
            >
              Click here to claim gift
            </button>
          </div>
        )}

        {settings.template === 'freeProducts' && (
          <FreeProductList productList={freeProducts} onProductClick={onSubmit} />
        )}

        <div className='free-product-modal-description'>
          {settings.template === 'freeProducts' && (
            <i className='far fa-chevron-double-down animate__animated animate__fadeInDown animate__infinite animate__slow'></i>
          )}
          {settings.terms}
        </div>

        <div className='free-product-modal-buttons'>
          <div className={`free-product-modal-buttons-btn`} id='warranty-no' onClick={() => onSubmit()}>
            NO, I DON’T LIKE FREE STUFF
          </div>
        </div>
      </div>
    </Modal>
  );
}
