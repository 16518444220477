import React, { useState } from 'react';
import Modal from 'react-modal';
import ReCAPTCHA from 'react-google-recaptcha';
import validator from 'validator';
import xssFilters from 'xss-filters';
import '../Custom.sass';
import './ContactUsModal.sass';

export default function ContactUsModal({ company, modalIsOpen, closeModal, onFormSubmit }) {
  Modal.setAppElement('#root');

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 100,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  function validateForm() {
    if (validator.isEmpty(firstName)) {
      return setError('Please provide first name');
    }
    if (validator.isEmpty(lastName)) {
      return setError('Please provide last name');
    }
    if (validator.isEmpty(message)) {
      return setError('Please provide message');
    }
    if (!validator.isEmail(email)) {
      return setError('Please provide correct email');
    }
    if (!isCaptchaPassed) {
      return setError('Please submit the captcha');
    }

    setError('');
    setSuccess('Thank you for your message. We will contact you soon.');

    onFormSubmit({
      firstName: xssFilters.inHTMLData(firstName),
      lastName: xssFilters.inHTMLData(lastName),
      email: xssFilters.inHTMLData(email),
      message: xssFilters.inHTMLData(message),
    });

    setTimeout(() => {
      handleClose();
      setError('');
      setSuccess('');
    }, 4000);
  }

  function handleClose() {
    closeModal();
  }

  function onCaptchaChange(value) {
    if (value) {
      setIsCaptchaPassed(true);
    }
  }

  return (
    <Modal isOpen={modalIsOpen} onRequestClose={handleClose} style={customStyles}>
      <div className='custom-modal'>
        <button onClick={handleClose} className='custom-modal__close'>
          <i className='far fa-times'></i>
        </button>
        <div className='custom-modal__title'>Contact Us</div>
        <div className='custom-modal__row'>{company.name}</div>
        <div className='custom-modal__row'>{company.address}</div>
        <div className='custom-modal__row'>
          <a href={`tel:${company.phone}`}>{company.phone}</a>
        </div>
        <div className='custom-modal__row'>
          <a href={`mailto:${company.email}`}>{company.email}</a>
        </div>
      </div>

      <div className='contact-form'>
        <div className='contact-form__title'>You can also contact us via this form</div>
        <div className='contact-form__field'>
          <input
            type='text'
            required
            placeholder='First name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className='contact-form__field'>
          <input
            type='text'
            required
            placeholder='Last name'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className='contact-form__field'>
          <input
            type='email'
            required
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='contact-form__field'>
          <textarea
            placeholder='Message'
            required
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className='contact-form__captcha'>
          <ReCAPTCHA sitekey='6LfA-kEaAAAAADqY01IzWz9nV82t0PayB_hCcxux' onChange={onCaptchaChange} />
        </div>
        <button className='contact-form__submit' onClick={() => validateForm()}>
          Submit
        </button>
        {error && <div className='contact-form__error'>{error}</div>}
        {success && <div className='contact-form__success'>{success}</div>}
      </div>
    </Modal>
  );
}
