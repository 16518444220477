/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Reviews from '../../components/Reviews/Reviews';
import OrderForm from '../../components/OrderForm/OrderForm';
import ProductInfo from '../../components/ProductInfo/ProductInfo';
import Banner from '../../components/Banner/Banner';
import Header from '../../components/Header/Header';
import TestCheckout from './TestCheckout';
import TestCheckout2 from './TestCheckout2';

import { buildQueryParams, getMaxDiscount } from '../../utils/helpers';
import './Checkout.sass';

export default function Checkout({
  offer,
  reviews,
  crossSells,
  onTermsClick,
  onCreateOrder,
  onPrivacyClick,
  isOneLineCheckout,
  isOneProductCheckout,
}) {
  const [banners, setBanners] = useState([]);
  const queryParams = buildQueryParams(location);

  async function getBanners() {
    try {
      const response = await axios.get('https://launch-ra-api-92gp7.ondigitalocean.app/banners');
      if (response.data) {
        setBanners(response.data);
      }
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  useEffect(() => {
    if (!offer.isBannerDisabled) {
      getBanners();
    }

    // eslint-disable-next-line
  }, []);

  if (queryParams.template === '1') {
    return <TestCheckout offer={offer} onCreateOrder={onCreateOrder} onTermsClick={onTermsClick} />;
  }

  if (queryParams.template === '2') {
    return <TestCheckout2 offer={offer} onCreateOrder={onCreateOrder} onTermsClick={onTermsClick} />;
  }

  return (
    <>
      <Header logoSrc={offer.logoSrc} />
      <main className='page-main'>
        <Banner banners={banners} discount={getMaxDiscount(offer)} />
        <div className='container'>
          <div className='page-main-layout'>
            <div className='page-main-layout__left'>
              <OrderForm
                offer={offer}
                crossSells={crossSells}
                onCreateOrder={onCreateOrder}
                onPrivacyClick={onPrivacyClick}
                onTermsClick={onTermsClick}
                isOneLineCheckout={isOneLineCheckout}
                isOneProductCheckout={isOneProductCheckout}
              />
            </div>
            <div
              className={`page-main-layout__right ${isOneProductCheckout && 'page-main-layout__right--opc'}`}
            >
              <ProductInfo offer={offer} />
              <div className='olc-reviews-desktop'>
                {isOneLineCheckout && <Reviews isSlider={false} reviews={reviews} />}
              </div>
            </div>
          </div>
          {!isOneLineCheckout && queryParams.reviews !== '0' && (
            <Reviews isSlider reviews={reviews} offerId={offer._id} />
          )}
          <div className='olc-reviews-mobile'>
            {isOneLineCheckout && <Reviews isSlider={false} reviews={reviews} />}
          </div>
        </div>
      </main>
      {/* <ProofPopup offer={offer} data={proofPopups} /> */}
    </>
  );
}
