/* eslint-disable no-restricted-globals */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { states } from '../../components/ShippingInfo/states';
import api from '../../api/api';
import { buildQueryParams, getDollarString, getSavingsPercent } from '../../utils/helpers';
import { validateStep } from '../../utils/orderFormUtils';
import styles from './TestCheckout2.module.sass';
import logoImage from './testCheckout2Images/Logo.webp';
import heroImage from './testCheckout2Images/hero.webp';
import b1 from './testCheckout2Images/3.webp';
import b2 from './testCheckout2Images/4.webp';
import b3 from './testCheckout2Images/5.webp';
import b4 from './testCheckout2Images/6.webp';
import discountImage from './testCheckout2Images/7.webp';
import p1 from './testCheckout2Images/1x.webp';
import p2 from './testCheckout2Images/2x.webp';
import p3 from './testCheckout2Images/3x.webp';
import p4 from './testCheckout2Images/4x.webp';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PayPalButtons from '../../components/PayPalButtons/PayPalButtons';
import NumberFormat from 'react-number-format';
import visa from './testCheckout2Images/visa.webp';
import master from './testCheckout2Images/master.webp';
import amex from './testCheckout2Images/amex.webp';
import safe from './testCheckout2Images/safe.webp';

const productImages = [p1, p2, p3, p4];

export default function TestCheckout({ offer, onCreateOrder, onTermsClick }) {
  const queryParams = buildQueryParams(location);
  const initialProduct = offer.productsData[0];
  const [clientToken, setClientToken] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(initialProduct);
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 15,
    seconds: 0,
  });
  const [state, setState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    shippingAddress1: '',
    shippingCity: '',
    shippingState: '',
    shippingCountry: 'US',
    shippingZip: '',
    phone: '',
    creditCardNumber: '',
    expirationDate: '',
    CVV: '',
    billingFirstName: '',
    billingLastName: '',
    billingAddress1: '',
    billingCity: '',
    billingState: '',
    billingCountry: 'US',
    billingZip: '',
    billingSameAsShipping: true,
    brand: offer.brand,
    isTermsAgreed: false,
    ipAddress: '',
    productId: initialProduct.id,
    productQty: initialProduct.quantity,
    productName: initialProduct.name,
    productPrice: initialProduct.price,
    agreeSms: false,
    fbc: getFbcValue(),
    fbp: Cookies.get('_fbp'),
    custom_fields: getCustomFields(),
    orderTotal: initialProduct.price * 1 + initialProduct.shippingPrice * 1,
    campaignId: offer.campaignId,
    shippingId: initialProduct.shippingId || '',
    offers: [
      {
        billing_model_id: offer.productDetails[initialProduct.id].billingModelId,
        offer_id: offer.productDetails[initialProduct.id].offerId,
        product_id: initialProduct.id,
        price: initialProduct.price,
        productQty: initialProduct.quantity,
        productName: initialProduct.name,
        isMainProduct: true,
      },
    ],
  });

  useEffect(() => {
    const orderTotal = selectedProduct.price * 1 + selectedProduct.shippingPrice * 1;
    const productDetail = offer.productDetails[selectedProduct.id];

    const stickyOffer = {
      billing_model_id: productDetail.billingModelId,
      offer_id: productDetail.offerId,
      product_id: selectedProduct.id,
      price: selectedProduct.price,
      productQty: selectedProduct.quantity,
      productName: selectedProduct.name,
      isMainProduct: true,
    };

    setState({
      ...state,
      orderTotal,
      offers: [stickyOffer],
      shippingId: selectedProduct.shippingId,
      productId: selectedProduct.id,
      productQty: selectedProduct.quantity,
      productName: selectedProduct.name,
      productPrice: selectedProduct.price,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        let { hours, minutes, seconds } = prevTime;

        if (seconds > 0) {
          seconds -= 1;
        } else if (minutes > 0) {
          seconds = 59;
          minutes -= 1;
        } else if (hours > 0) {
          seconds = 59;
          minutes = 59;
          hours -= 1;
        } else {
          clearInterval(intervalId); // Stop the countdown when it reaches zero
        }

        return { hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/braintree/token');
      const clientToken = data.clientToken;
      setClientToken(clientToken);
    })();
  }, []);

  useEffect(() => {
    let componentMounted = true;

    async function getUserIpAddress() {
      try {
        const res = await axios.get('https://api.ipify.org?format=json');
        const clientIp = res.data?.ip || '0.0.0.0';

        if (res.data && componentMounted) {
          setState({ ...state, ipAddress: clientIp });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Ip address not found.');
      }
    }

    getUserIpAddress();
    return () => {
      componentMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getFbcValue() {
    if (Cookies.get('_fbc')) {
      return Cookies.get('_fbc');
    }

    if (queryParams.fbclid) {
      const timestamp = new Date().getTime();
      return `fb.1.${timestamp}.${queryParams.fbclid}`;
    }

    return '';
  }

  function getCustomFields() {
    const result = [];
    const fields = {
      17: queryString.parse(location.search).wbraid,
      16: queryString.parse(location.search).gbraid,
      15: queryString.parse(location.search).gclid,
    };

    Object.keys(fields).forEach((key) => {
      if (fields[key]) {
        result.push({
          id: key,
          value: fields[key],
        });
      }
    });

    return result;
  }

  function cardExpiry(val) {
    const month = val.substring(0, 2);
    const year = val.substring(2, 4);

    if (month.length === 1 && month > 1) {
      return `0${month}/${year}`;
    }

    if (month.length === 2) {
      if (parseInt(month, 10) > 12) {
        return `12/${year}`;
      }
      return `${month}/${year}`;
    }

    return `${month}/${year}`;
  }

  function removeNumbers(str) {
    return str.replace(/\d+/g, '');
  }

  function validateForm(data) {
    const validationMessages = {
      email: 'The email address you entered is not valid. Please enter a valid email address.',
      phone: 'The phone number you entered is not valid. Please enter a 10-digit phone number.',
      creditCardNumber:
        'The credit card number you entered is not valid. Please enter a valid credit card number.',
      firstName: 'First name is required. Please enter your first name.',
      lastName: 'Last name is required. Please enter your last name.',
      shippingAddress1: 'Shipping address is required. Please enter your shipping address.',
      shippingCity: 'City is required. Please enter your city.',
      shippingState: 'State is required. Please enter your state.',
      shippingZip: 'ZIP code is required. Please enter your ZIP code.',
      expirationDate: "Expiration date is required. Please enter your credit card's expiration date.",
      CVV: "CVV is required. Please enter your credit card's CVV.",
      isTermsAgreed: 'You must agree to the terms and conditions to proceed.',
    };

    const errors = validateStep(data, validationMessages);

    if (!data.isTermsAgreed) {
      errors.isTermsAgreed = validationMessages.isTermsAgreed;
    }

    return errors;
  }

  function handleExpressApprove(data) {
    onCreateOrder(data);
  }

  function handleFormSubmit() {
    const validationErrors = validateForm(state);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
      onCreateOrder(state);
    }
  }

  return (
    <div>
      {/* Header */}
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.headerInner}>
            <div className={styles.headerLeft}>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                <path d='M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM192 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm160 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM337 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L175 303c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L337 209z' />
              </svg>
              <p>
                Hurry! <b>{getSavingsPercent(selectedProduct.oldPrice, selectedProduct.price)}% discount</b>{' '}
                reserved for:
              </p>
            </div>
            <div className={styles.headerRight}>
              <div className={styles.headerRightTime}>
                <b>{String(timeLeft.hours).padStart(2, '0')}</b> <p>HOURS</p>
              </div>
              <b>:</b>
              <div className={styles.headerRightTime}>
                <b>{String(timeLeft.minutes).padStart(2, '0')}</b> <p>MINUTES</p>
              </div>
              <b>:</b>
              <div className={styles.headerRightTime}>
                <b>{String(timeLeft.seconds).padStart(2, '0')}</b>
                <p>SECONDS</p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Hero */}
      <section className={styles.hero}>
        <div className={styles.container}>
          <div className={styles.heroInner}>
            <div className={styles.heroLeft}>
              <img src={logoImage} alt='' />
            </div>
            <div className={styles.heroRight}>
              <img src={heroImage} alt='' />
            </div>
          </div>
        </div>
      </section>
      {/* Checkout */}
      <div className={styles.container}>
        <section className={styles.checkout}>
          {/* Checkout Left */}
          <article className={styles.checkoutLeft}>
            {/* Benefits and Discount */}
            <div className={styles.benefitsAndDiscount}>
              <div className={styles.benefits}>
                <div className={styles.benefit}>
                  <img src={b1} alt='Complete Knee Support' />
                  <p>Complete Knee Support</p>
                </div>
                <div className={styles.benefit}>
                  <img src={b2} alt='#1 Rated for Pain Relief' />
                  <p>#1 Rated for Pain Relief</p>
                </div>
                <div className={styles.benefit}>
                  <img src={b3} alt='Breathable & Sweat Resistant' />
                  <p>Breathable & Sweat Resistant</p>
                </div>
                <div className={styles.benefit}>
                  <img src={b4} alt='3D weaving Technology' />
                  <p>3D weaving Technology</p>
                </div>
              </div>
              <div className={styles.discount}>
                <img src={discountImage} alt='' />
                <div className={styles.discountContent}>
                  <p>
                    <i className='fa-solid fa-square-check'></i>{' '}
                    <span>
                      Your {getSavingsPercent(selectedProduct.oldPrice, selectedProduct.price)}% APPLIED
                      SUCCESSFULLY
                    </span>{' '}
                  </p>
                  <p>
                    <span>LIMITED TIME PROMO:</span> Compressa Knee Sleeve is available at the price of:{' '}
                    <s>{getDollarString(selectedProduct.oldPrice)}</s>{' '}
                    <b>{getDollarString(selectedProduct.price)}</b> (
                    {getSavingsPercent(selectedProduct.oldPrice, selectedProduct.price)}% Discount)
                  </p>
                </div>
              </div>
            </div>
            {/* Step 1 */}
            <div className={styles.step1}>
              <div className={styles.stepTitle}>
                <i className='fa-solid fa-person-walking'></i> Step 1: Select Order quantity
              </div>
              {/* Products */}
              <div className={styles.products}>
                {offer.productsData.map((product) => (
                  <div
                    key={product.id}
                    className={`${styles.product} ${
                      selectedProduct.id === product.id && styles.productActive
                    }`}
                    onClick={() => setSelectedProduct(product)}
                  >
                    {offer.mostPopularProductIds.includes(product.id) && (
                      <div className={styles.productPopular}>Most Popular</div>
                    )}
                    <div className={styles.productImage}>
                      <img src={productImages[product.quantity * 1 - 1]} alt='' />
                    </div>
                    <div className={styles.productContent}>
                      <b>{product.quantity}x</b>
                      <p dangerouslySetInnerHTML={{ __html: removeNumbers(product.title) }}></p>
                      <s>{getDollarString(product.oldPrice)}</s>
                      <span>{getDollarString(product.price)}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </article>
          {/* Checkout Right */}
          <article className={styles.checkoutRight}>
            {/* Express Checkout */}
            {clientToken && queryParams.paypal === '1' && (
              <div className={styles.expressCheckout}>
                <PayPalScriptProvider
                  options={{
                    vault: true,
                    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    components: 'buttons',
                    dataClientToken: clientToken,
                  }}
                >
                  <PayPalButtons
                    orderData={state}
                    checkoutTotal={state.orderTotal}
                    onApprove={handleExpressApprove}
                  />
                </PayPalScriptProvider>
              </div>
            )}
            {/* Order Form */}
            <div className={styles.orderForm}>
              <div className={styles.stepTitle}>
                <i className='fa-solid fa-info'></i> Step 2: Your Information
              </div>
              {/* address inputs */}
              <label htmlFor='email'>Email Address</label>
              <input
                placeholder='Email Address'
                type='email'
                name='email'
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
              />

              <label htmlFor='firstName'>First Name</label>
              <input
                placeholder='First Name'
                type='text'
                name='firstName'
                value={state.firstName}
                onChange={(e) => setState({ ...state, firstName: e.target.value })}
              />

              <label htmlFor='lastName'>Last Name</label>
              <input
                placeholder='Last Name'
                type='text'
                name='lastName'
                value={state.lastName}
                onChange={(e) => setState({ ...state, lastName: e.target.value })}
              />

              <label htmlFor='phone'>Phone Number</label>
              <input
                placeholder='Phone Number'
                type='tel'
                name='phone'
                value={state.phone}
                onChange={(e) => setState({ ...state, phone: e.target.value })}
              />

              <label htmlFor='shippingAddress1'>Shipping Address</label>
              <input
                placeholder='Shipping Address'
                type='text'
                name='shippingAddress1'
                value={state.shippingAddress1}
                onChange={(e) => setState({ ...state, shippingAddress1: e.target.value })}
              />

              <label htmlFor='shippingCity'>City</label>
              <input
                placeholder='City'
                type='text'
                name='shippingCity'
                value={state.shippingCity}
                onChange={(e) => setState({ ...state, shippingCity: e.target.value })}
              />

              <label htmlFor='shippingCountry'>Country</label>
              <select
                name='shippingCountry'
                value={state.shippingCountry}
                onChange={(e) => setState({ ...state, shippingCountry: e.target.value })}
              >
                <option value='US'>United States</option>
                <option value='CA'>Canada</option>
              </select>

              <div className={styles.formRow}>
                <div>
                  <label htmlFor='shippingState'>State</label>
                  <select
                    name='shippingState'
                    value={state.shippingState}
                    onChange={(e) => setState({ ...state, shippingState: e.target.value })}
                  >
                    {Object.entries(states[state.shippingCountry]).map(([code, name], index) => {
                      return (
                        <option key={index} value={code}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div>
                  <label htmlFor='shippingZip'>Zip Code</label>
                  <input
                    placeholder='Zip/Postal Code'
                    type='text'
                    name='shippingZip'
                    value={state.shippingZip}
                    onChange={(e) => setState({ ...state, shippingZip: e.target.value })}
                  />
                </div>
              </div>

              <div className={styles.formShipping}>
                <b>Shipping:</b>
                <span>
                  {selectedProduct.shippingPrice > 0
                    ? getDollarString(selectedProduct.shippingPrice)
                    : 'FREE'}
                </span>
              </div>

              {/* payment inputs */}

              <div className={styles.stepTitle}>
                <i className='fa-solid fa-credit-card'></i> Step 3: Payment Methods
              </div>

              <div className={styles.creditCardBlock}>
                <div className={styles.creditCardBlockHeader}>
                  <b>Credit Card</b>
                  <div className={styles.creditCardBlockHeaderCards}>
                    <img src={visa} alt='visa' />
                    <img src={master} alt='mastercard' />
                    <img src={amex} alt='amex' />
                  </div>
                </div>
                <label htmlFor='creditCardNumber'>Card Number</label>
                <NumberFormat
                  name='creditCardNumber'
                  format='#### #### #### ####'
                  placeholder='.... .... .... ....'
                  value={state.creditCardNumber}
                  onValueChange={(values) => {
                    const { value } = values;
                    setState({ ...state, creditCardNumber: value });
                  }}
                />

                <div className={styles.formRow}>
                  <div>
                    <label htmlFor='expirationDate'>Date of Expiry</label>
                    <NumberFormat
                      name='expirationDate'
                      format={cardExpiry}
                      placeholder='MM/YY'
                      mask={['M', 'M', 'Y', 'Y']}
                      onValueChange={(values) => {
                        const { value } = values;
                        setState({ ...state, expirationDate: value });
                      }}
                      value={state.expirationDate}
                    />
                  </div>
                  <div>
                    <label htmlFor='CVV'>CVV</label>
                    <NumberFormat
                      name='CVV'
                      format='####'
                      placeholder='CVV'
                      value={state.CVV}
                      onValueChange={(values) => {
                        const { value } = values;
                        setState({ ...state, CVV: value });
                      }}
                    />
                  </div>
                </div>

                <div className={styles.termsCheckbox}>
                  <input
                    type='checkbox'
                    id='terms'
                    name='terms'
                    checked={state.isTermsAgreed}
                    onChange={(e) => setState({ ...state, isTermsAgreed: e.target.checked })}
                  />
                  <label htmlFor='terms'>
                    I agree to the{' '}
                    <b
                      onClick={(e) => {
                        e.preventDefault();
                        onTermsClick();
                      }}
                    >
                      terms and conditions
                    </b>
                  </label>
                </div>
                {/* button */}
                <button onClick={() => handleFormSubmit()} className={styles.submitButton} type='button'>
                  Order Now
                </button>
              </div>

              <div className={styles.checkoutRightFooter}>
                <img src={safe} alt='safe icons' />
                <p>
                  <b>
                    <span>
                      <i className='fa-solid fa-lock'></i> SSL
                    </span>{' '}
                    SECURE PAYMENT
                  </b>
                  <span>Your Personal Details Are Securely Encrypted With 256-Bit-SSL</span>
                </p>

                <div className={styles.checkoutRightFooterBenefits}>
                  <div className={styles.checkoutRightFooterBenefit}>
                    <i className='fa-solid fa-check'></i>
                    <p>
                      Increase <b>Blood Circulation</b> and <b>Oxygen Delivery</b>
                    </p>
                  </div>
                  <div className={styles.checkoutRightFooterBenefit}>
                    <i className='fa-solid fa-check'></i>
                    <p>
                      Reduce <b>Stress, Swelling</b> & Allows <b>Faster Recovery</b>
                    </p>
                  </div>
                  <div className={styles.checkoutRightFooterBenefit}>
                    <i className='fa-solid fa-check'></i>
                    <p>
                      Ergonomically Designed For A Full <b>Range Of Motion</b>
                    </p>
                  </div>
                  <div className={styles.checkoutRightFooterBenefit}>
                    <i className='fa-solid fa-check'></i>
                    <p>
                      Flexible, <b>Anti-Slip</b> & Breathable Fabric
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Checkout Right Footer */}
          </article>
        </section>
      </div>
      {showPopup && (
        <div className={styles.errorPopup}>
          <ul>
            {Object.entries(errors).map(([field, message]) => (
              <li key={field}>{message}</li>
            ))}
          </ul>

          <button onClick={() => setShowPopup(false)}>Close</button>
        </div>
      )}
    </div>
  );
}
